import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom';
//import PrivateRoute from './privateRoute';
// import DoctorHomepage from './components/pages/doctor/Homepage';
// import Receptionisthomepage from './components/pages/human-resource/Receptionisthomepage';
// import Pharmacisthomepage from './components/pages/human-resource/Pharmacisthomepage';
// import Adminhomepage from './components/pages/Adminhomepage';
import { Toaster } from 'react-hot-toast';

// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));
// Pages
const Animation = React.lazy(() => import("./components/pages/Animation"));
const Widgets = React.lazy(() => import("./components/pages/Widgets"));
// appointment
const Addappointment = React.lazy(() => import("./components/pages/appointment/Addappointment"));
const Appointmentlist = React.lazy(() => import("./components/pages/appointment/Appointmentlist"));

//Appointment pages For Receptionist
const AddappointmentReceptionist = React.lazy(() => import("./components/pages/appointment/AddappointmentReceptionist"))
const AppointmentlistReceptionist = React.lazy(() => import("./components/pages/appointment/AppointmentlistReceptionist"))

//Appointment list for Doctor
const AppointmentlistDoctor = React.lazy(() => import("./components/pages/appointment/AppointmentlistDoctor"));

//Appointment list for Patient
const AppointmentlistPatient = React.lazy(() => import("./components/pages/appointment/AppointmentlistPatient"));

//Appointment Vital Signs
const AddAppointmentVitalSigns = React.lazy(() => import("./components/pages/appointment-vital-signs/AddAppointmentVitalSigns"));
const EditAppointmentVitalSigns = React.lazy(() => import("./components/pages/appointment-vital-signs/EditAppointmentVitalSigns"));

//Appointment Vital Signs for Doctor
const EditAppointmentVitalSignsDoctor = React.lazy(() => import("./components/pages/appointment-vital-signs/EditAppointmentVitalSignsDoctor"));

// apps
const Chat = React.lazy(() => import("./components/pages/apps/Chat"));
const Email = React.lazy(() => import("./components/pages/apps/Email"));
const Todolist = React.lazy(() => import("./components/pages/apps/Todolist"));

//room manager
const AddRoomCategory = React.lazy (() => import("./components/pages/room-manager/AddRoomCategory"));
const RoomCategoryList = React.lazy(() => import ("./components/pages/room-manager/RoomCategoryList"));

const AddRoom = React.lazy(() => import("./components/pages/room-manager/AddRoom"));
const RoomList = React.lazy(() => import("./components/pages/room-manager/RoomList"));

// bed manager
const Addbedcategory = React.lazy (() => import("./components/pages/bed-manager/Addbedcategory"));
const Bedcategorylist = React.lazy(() => import ("./components/pages/bed-manager/Bedcategorylist"));

const Addbed = React.lazy(() => import("./components/pages/bed-manager/Addbed"));
const Bedlist = React.lazy(() => import("./components/pages/bed-manager/Bedlist"));

// Bed list page for Receptionist
const BedlistReceptionist = React.lazy(()=> import("./components/pages/bed-manager/BedlistReceptionist"))
// charts
const Chartjs = React.lazy(() => import("./components/pages/charts/Chartjs"));
const Googlecharts = React.lazy(() => import("./components/pages/charts/Googlecharts"));
// dashboard
const Clientmanagement = React.lazy(() => import("./components/pages/dashboard/Clientmanagement"));
const Projectmanagement = React.lazy(() => import("./components/pages/dashboard/Projectmanagement"));
const Socialmedia = React.lazy(() => import("./components/pages/dashboard/Socialmedia"));
const Webanalytics = React.lazy(() => import("./components/pages/dashboard/Webanalytics"));
// department
const Adddepartment = React.lazy(() => import("./components/pages/department/Adddepartment"));
const Departmentlist = React.lazy(() => import("./components/pages/department/Departmentlist"));
// department list page for receptionist
const DepartmentlistReceptionist = React.lazy(() => import(("./components/pages/department/DepartmentlistReceptionist")));

//patient
const PatientHomepage = React.lazy(() => import("./components/pages/patient/Homepage"));

// doctor
const Adddoctor = React.lazy(() => import("./components/pages/doctor/Adddoctor"));
const Doctorlist = React.lazy(() => import("./components/pages/doctor/Doctorlist"));
const DoctorHomepage = React.lazy(() => import("./components/pages/doctor/Homepage"));
// doctor list page for receptionist
const DoctorlistReceptionist = React.lazy(() => import("./components/pages/doctor/DoctolistReceptionist"));
// doctor schedule
const Addschedule = React.lazy(() => import("./components/pages/doctor-schedule/Addschedule"));
const Schedulelist = React.lazy(() => import("./components/pages/doctor-schedule/Schedulelist"));
// doctor Schedule page for Receptionist
const SchedulelistReceptionist = React.lazy(() => import("./components/pages/doctor-schedule/SchedulelistReceptionist"));
// Consultation for admin
const Addconsultation = React.lazy(() => import("./components/pages/consultation/Addconsultation"));
const Consultationlist = React.lazy(() => import("./components/pages/consultation/Consultationlist"));
const Consultationdetails = React.lazy(()=>import("./components/pages/consultation/Consultationdetails"));
const Editconsultation = React.lazy(() => import("./components/pages/consultation/Editconsultation"));
const Deleteconsultation = React.lazy(() => import("./components/pages/consultation/Deleteconsultation"));

const PatientconsultationList = React.lazy(() => import("./components/pages/consultation/PatientconsultationList"));

// Consultation for doctor
const AddconsultationDoctor = React.lazy(() => import("./components/pages/consultation/AddconsultationDoctor"))
const ConsultationlistDoctor = React.lazy(() =>import("./components/pages/consultation/ConsultationslistDoctor"));
const ConsultationdetailsDoctor = React.lazy(() => import("./components/pages/consultation/ConsultationdetailsDoctor"));
const EditconsultationDoctor = React.lazy(() => import("./components/pages/consultation/EditconsultationDoctor"));
const DeleteconsultationDoctor = React.lazy(() => import("./components/pages/consultation/DeleteconsultationDoctor"));

// Consultation for patient
const ConsultationlistPatient = React.lazy(() =>import("./components/pages/consultation/ConsultationlistPatient"));
const ConsultationdetailsPatient = React.lazy(() => import("./components/pages/consultation/ConsultationdetailsPatient"));

//Precription for Admin
const Addprescription = React.lazy(() => import("./components/pages/prescription/Addprescription"));
const Prescriptionlist = React.lazy(() => import("./components/pages/prescription/Prescriptionlist"));
const Prescriptiondetails = React.lazy(() => import("./components/pages/prescription/Prescriptiondetails"));
const Editprescription = React.lazy(() => import("./components/pages/prescription/Editprescription"));
const Deleteprescription = React.lazy(() => import("./components/pages/prescription/Deleteprescription"));

//Precription for Doctor
const AddprescriptionDoctor = React.lazy(() => import("./components/pages/prescription/AddprescriptionDoctor"));
const PrescriptiondetailsDoctor = React.lazy(() => import("./components/pages/prescription/PrescriptiondetailsDoctor"));
const EditprescriptionDoctor = React.lazy(() => import("./components/pages/prescription/EditprescriptionDoctor"));
const DeleteprescriptionDoctor = React.lazy(() => import("./components/pages/prescription/DeleteprescriptionDoctor"))

//Prescription list for patient/pharmacist/pathologist
const PrescriptionlistPatient = React.lazy(() => import("./components/pages/prescription/PrescriptionlistPatient"))
const PrescriptiondetailsPatient = React.lazy(() => import("./components/pages/prescription/PrescriptiondetailsPatient"))

const AdmittedPatientPrescriptionListPharmacist = React.lazy(() => import("./components/pages/admitted-patient-prescription-list/AdmittedPatientPrescriptionListPharmacist")); 
const AdmittedPatientPrescriptionListPathologist = React.lazy(() => import("./components/pages/admitted-patient-prescription-list/AdmittedPatientPrescriptionListPathologist")); 
const PrescriptiondetailsPharmacist = React.lazy(() => import("./components/pages/prescription/PrescriptiondetailsPharmacist"));
const PrescriptionlistPharmacist = React.lazy(() => import("./components/pages/prescription/PrecriptionlistPharmacist"));
const PrescriptionlistPathologist = React.lazy(() => import("./components/pages/prescriptions-test/PrescriptionlistPathologist"));

const PrescriptionListReceptionist = React.lazy(() => import("./components/pages/prescription/PrescriptionlistReceptionist"));
const PrescriptionDetailsReceptionist = React.lazy(() => import("./components/pages/prescription/PrescriptionDetailsReceptionist"));

//Prescription test for admin
const Addtest  = React.lazy(() => import("./components/pages/prescriptions-test/Addtest"));
const Prescriptiontestdetails = React.lazy(() => import("./components/pages/prescriptions-test/Prescriptiontestdetails"));
const Edittest  = React.lazy(() => import("./components/pages/prescriptions-test/Edittest"));
const Deletetest  = React.lazy(() => import("./components/pages/prescriptions-test/Deletetest"));

//Prescription test for Doctor
const AddtestDoctor  = React.lazy(() => import("./components/pages/prescriptions-test/AddtestDoctor"));
const PrescriptiontestdetailsDoctor = React.lazy(() => import("./components/pages/prescriptions-test/PrescriptiontestdetailsDoctor"));
const EdittestDoctor  = React.lazy(() => import("./components/pages/prescriptions-test/EdittestDoctor"));
const DeletetestDoctor  = React.lazy(() => import("./components/pages/prescriptions-test/DeletetestDoctor"));

//Prescription test for patient
const PrescriptiontestdetailsPatient= React.lazy(() => import("./components/pages/prescriptions-test/PrescriptiontestdetailsPatient"));

//Prescription test list for pathologist, Receptionist
const EditTestPathologist  = React.lazy(() => import("./components/pages/prescriptions-test/EditTestPathologist"));
const PrescriptionTestListPathologist = React.lazy(() => import("./components/pages/prescriptions-test/PrescriptionTestListPathologist"));

const PrescriptiontestdetailsPathologist = React.lazy(() => import("./components/pages/prescriptions-test/PrescriptiontestdetailsPathologist"));
const PrescriptionTestDetailsReceptionist = React.lazy(() => import("./components/pages/prescriptions-test/PrescriptiontestdetailsReceptionist"));

//Followup for admin
const Followuplist = React.lazy(() => import("./components/pages/followup/Followuplist"));
const Addfollowup = React.lazy(() => import("./components/pages/followup/Addfollowup"));

//Followup for doctor
const AddfollowupDoctor = React.lazy(() => import("./components/pages/followup/AddfollowupDoctor"));
const FollowuplistDoctor = React.lazy(() => import("./components/pages/followup/FollowuplistDoctor"));

//Nurse
const NurseHomepage = React.lazy(() => import("./components/pages/nurse/Homepage"));

// available drugs/accessories/tests/services

//Admin
const AddDrugs = React.lazy(() => import("./components/pages/available-drugs/AddDrugs"));
const AddAccessories = React.lazy(() => import ("./components/pages/available-accessories/AddAccessories"));
const AddTests = React.lazy(() => import("./components/pages/available-tests/AddTests"))
const AddProcedures = React.lazy(() => import("./components/pages/available-procedures/AddProcedures"))
const AddServices = React.lazy(() => import("./components/pages/available-services/AddServices"))

const Availabledrugs = React.lazy(() => import("./components/pages/available-drugs/Availabledrugs"));
const Availableaccessories = React.lazy(() => import("./components/pages/available-accessories/Availableaccessories"));
const Availabletests = React.lazy(() => import("./components/pages/available-tests/Availabletests"));
const Availableprocedures = React.lazy(() => import("./components/pages/available-procedures/Availableprocedures"));
const AvailableServices =React.lazy(() => import("./components/pages/available-services/AvailableServices"));

//pharmacist
const AddDrugsPharmacist = React.lazy(() => import("./components/pages/available-drugs/AddDrugsPharmacist"));
const AddAccessoriesPharmacist = React.lazy(() => import ("./components/pages/available-accessories/AddAccessoriesPharmacist"));
const AvailableaccessoriesPharmacist = React.lazy(() => import("./components/pages/available-accessories/AvailableaccessoriesPharmacist"));
const AvailabledrugsPharmacist = React.lazy(() => import("./components/pages/available-drugs/AvailabledrugsPharmacist"));

//pathologist
const AvailabletestsPathologist = React.lazy(() => import("./components/pages/available-tests/AvailabletestsPathologist"));

// form
const Formlayouts = React.lazy(() => import("./components/pages/form/Formlayouts"));
const Formvalidation = React.lazy(() => import("./components/pages/form/Formvalidation"));
const Formwizard = React.lazy(() => import("./components/pages/form/Formwizard"));
const Fromelements = React.lazy(() => import("./components/pages/form/Fromelements"));

// human resource
const Addemployee = React.lazy(() => import("./components/pages/human-resource/Addemployee"));
const Addnurse = React.lazy(() => import("./components/pages/human-resource/Addnurse"));
const Addpharmacist = React.lazy(() => import("./components/pages/human-resource/Addpharmacist"));
const Addrepresentative = React.lazy(() => import("./components/pages/human-resource/Addrepresentative"));
const Employeelist = React.lazy(() => import("./components/pages/human-resource/Employeelist"));
const Nurselist = React.lazy(() => import("./components/pages/human-resource/Nurselist"));
const Pharmacistlist = React.lazy(() => import("./components/pages/human-resource/Pharmacistlist"));
const Representativelist = React.lazy(() => import("./components/pages/human-resource/Representativelist"));

const Adminhomepage = React.lazy(() => import("./components/pages/Adminhomepage"));
const Receptionisthomepage = React.lazy(() => import("./components/pages/human-resource/Receptionisthomepage"));
const Pharmacisthomepage = React.lazy(() => import("./components/pages/human-resource/Pharmacisthomepage"));
const Pathologisthomepage = React.lazy(() => import("./components/pages/human-resource/Pathologisthomepage"));

// icons
const Flaticons = React.lazy(() => import("./components/pages/icons/Flaticons"));
const Fontawesome = React.lazy(() => import("./components/pages/icons/Fontawesome"));
const Materialize = React.lazy(() => import("./components/pages/icons/Materialize"));
// maps
const Googlemaps = React.lazy(() => import("./components/pages/maps/Googlemaps"));
const Vectormaps = React.lazy(() => import("./components/pages/maps/Vectormaps"));
// notice
const Addnotice = React.lazy(() => import("./components/pages/notice/Addnotice"));
const Noticelist = React.lazy(() => import("./components/pages/notice/Noticelist"));

const NoticelistReceptionist = React.lazy(()=> import(("./components/pages/notice/NoticelistReceptionist")))

// patient
const Addpatient = React.lazy(() => import("./components/pages/patient/Addpatient"));
const Patientlist = React.lazy(() => import("./components/pages/patient/Patientlist"));

// patient case report
const PatientCaseReport = React.lazy(() => import("./components/pages/patient-case-report/PaitentCaseReport"));
const PatientCaseReportReceptionist = React.lazy(() => import("./components/pages/patient-case-report/PatientCaseReportReceptionist"));

//Patient Admit Request List
const PatientAdmitRequestList = React.lazy(() => import("./components/pages/patient/PatientAdmitRequestList"))
const PatientAdmitRequestListReceptionist = React.lazy(() => import("./components/pages/patient/PatientAdmitRequestListReceptionist"))


//Admitted Patient
const Admitpatient = React.lazy(() => import("./components/pages/patient/Admitpatient"));
const Admittedpatientlist = React.lazy(() => import("./components/pages/patient/Admittedpatientlist"));
const Admittedpatientdetails = React.lazy(() => import("./components/pages/patient/Admittedpatientdetails"))
const Editadmittedpatientdetails = React.lazy(() => import("./components/pages/patient/Editadmittedpatientdetails"))
const Deleteadmittedpatientdetails = React.lazy(() => import("./components/pages/patient/Deletepatientadmitdetails"));
const Releasepatient = React.lazy(() => import("./components/pages/patient/Releasepatient"));

const AddAdmittedPatientFollowup = React.lazy(() => import("./components/pages/admitted-patient-followup/AddAdmittedPatientFollowup"))

//PatientProcedure information page for Admin
const AddPatientProcedure = React.lazy(() => import("./components/pages/admitted-patient-procedure/AddPatientProcedure"))
const EditPatientProcedure = React.lazy(() => import("./components/pages/admitted-patient-procedure/EditPatientProcedure"))
const DeletePatientProcedure = React.lazy(() => import("./components/pages/admitted-patient-procedure/DeletePatientProcedure"))

//PatientProcedure information page for receptionist
const AddPatientProcedureReceptionist = React.lazy(() => import("./components/pages/admitted-patient-procedure/AddPatientProcedureReceptionist"))
const EditPatientProcedureReceptionist = React.lazy(() => import("./components/pages/admitted-patient-procedure/EditPatientProcedureReceptionist"))
const DeletePatientProcedureReceptionist = React.lazy(() => import("./components/pages/admitted-patient-procedure/DeletePatientProcedureReceptionist"))

// patient pages for receptionist
const AddpatientReceptionist = React.lazy(()=>import("./components/pages/patient/AddpatientReceptionist"));
const PatientlistReceptionist = React.lazy(()=> import("./components/pages/patient/PatientlistReceptionist"));

//Admitted patient receptionist
const AdmitpatientReceptionist = React.lazy(() => import("./components/pages/patient/AdmitpatientReceptionist"));
const AdmittedpatientlistReceptionist = React.lazy(() => import("./components/pages/patient/AdmittedpatientlistReceptionist"))
const AdmittedpatientdetailsReceptionist = React.lazy(() => import("./components/pages/patient/AdmittedpatientdetailsReceptionist"))
const EditadmittedpatientdetailsReceptionist = React.lazy(() => import("./components/pages/patient/EditadmittedpatientdetailsReceptionist"))
const DeleteadmittedpatientdetailsReceptionist = React.lazy(() => import("./components/pages/patient/DeletepatientadmitdetailsReceptionist"));
const ReleasepatientReceptionist = React.lazy(() => import("./components/pages/patient/ReleasepatientReceptionist"));

//Patient list for pharmacist/pathologist
const AdmittedPatientListPharmacist = React.lazy(() => import("./components/pages/patient/AdmittedpatientlistPharmacist"))
const PatientlistPharmacist = React.lazy(() => import ("./components/pages/patient/PatientlistPharmacist"));
const AdmittedPatientListPathologist = React.lazy(() => import("./components/pages/patient/AdmittedPatientListPathologist"))
const PatientlistPathologist = React.lazy(() => import ("./components/pages/patient/PatientlistPathologist"));

// patient list page for doctors
const PatientlistDoctor = React.lazy(() => import("./components/pages/patient/PatientlistDoctor"));
const AdmittedpatientlistDoctor = React.lazy(() => import("./components/pages/patient/AdmittedpatientlistDoctor"));
const AdmittedpatientdetailsDoctor = React.lazy(() => import("./components/pages/patient/AdmittedpatientdetailsDoctor"));
const AddAdmittedPatientFollowupDoctor = React.lazy(() => import("./components/pages/admitted-patient-followup/AddAdmittedPatientFollowupDoctor"))

// payment
const Addpayment = React.lazy(() => import("./components/pages/payment/Addpayment"));
const Paymentinvoice = React.lazy(() => import("./components/pages/payment/Paymentinvoice"));
const Paymentlist = React.lazy(() => import("./components/pages/payment/Paymentlist"));
const PatientServiceInvoice = React.lazy(() => import("./components/pages/payment/PatientServiceInvoice"));

const PaymentlistReceptionist = React.lazy(() => import("./components/pages/payment/PaymentlistReceptionist"))
const PatientServiceInvoiceReceptionist = React.lazy(()  =>import("./components/pages/payment/PatientServiceInvoiceReceptionist"));

//Payment invoice for pharmacist/pathologist
const PaymentinvoicePharmacist = React.lazy(() => import("./components/pages/payment/PaymentinvoicePharmacist"));
const PaymentinvoicePathologist = React.lazy(() => import("./components/pages/payment/PaymentinvoicePathologist"));
const PathologyinvoiceReceptionist = React.lazy(()=> import("./components/pages/payment/PathologyinvoiceReceptionist"))

const PaymentDetails = React.lazy(() => import("./components/pages/payment/PaymentDetails"));
const PaymentDetailsReceptionist = React.lazy(() => import("./components/pages/payment/PaymentdetailsReceptionist"));

// Admitted Page Service Bill generate page
const AdmittedPatientServiceBill = React.lazy(() => import("./components/pages/admitted-patient-bill/AdmittedPatientServiceBill"))
const AdmittedPatientServiceBillReceptionist = React.lazy(() => import("./components/pages/admitted-patient-bill/AdmittedPatientServiceBillReceptionist"))
// popups
const Sweetalerts = React.lazy(() => import("./components/pages/popups/Sweetalerts"));
const Toast = React.lazy(() => import("./components/pages/popups/Toast"));
// prebuilt pages
const Comingsoon = React.lazy(() => import("./components/pages/prebuilt-pages/Comingsoon"));
const Defaultlogin = React.lazy(() => import("./components/pages/prebuilt-pages/Defaultlogin"));
const Defaultregister = React.lazy(() => import("./components/pages/prebuilt-pages/Defaultregister"));
const Error = React.lazy(() => import("./components/pages/prebuilt-pages/Error"));
const Faq = React.lazy(() => import("./components/pages/prebuilt-pages/Faq"));
const Invoice = React.lazy(() => import("./components/pages/prebuilt-pages/Invoice"));
const Lockscreen = React.lazy(() => import("./components/pages/prebuilt-pages/Lockscreen"));
const Modallogin = React.lazy(() => import("./components/pages/prebuilt-pages/Modallogin"));
const Modalregister = React.lazy(() => import("./components/pages/prebuilt-pages/Modalregister"));
const Portfolio = React.lazy(() => import("./components/pages/prebuilt-pages/Portfolio"));
const Userprofile = React.lazy(() => import("./components/pages/prebuilt-pages/Userprofile"));
//Receptionist user profile
const UserprofileReceptionist = React.lazy(()=>import(("./components/pages/prebuilt-pages/UserprofileReceptionist")));
// reports
const Doctorreport = React.lazy(() => import("./components/pages/reports/Doctorreport"));
const Patientreport = React.lazy(() => import("./components/pages/reports/Patientreport"));
const Totalreport = React.lazy(() => import("./components/pages/reports/Totalreport"));
// Report pages for receptionist
const DoctorreportReceptionist = React.lazy(() =>import("./components/pages/reports/DoctorreportReceptionist"));
const PatientreportReceptionist = React.lazy(() =>import("./components/pages/reports/PatientreportReceptionist"));
const TotalreportReceptionist = React.lazy(() =>import("./components/pages/reports/TotalreportReceptionist"));
// tables
const Basictables = React.lazy(() => import("./components/pages/tables/Basictables"));
const Datatables = React.lazy(() => import("./components/pages/tables/Datatables"));
// uiadvance
const Cropper = React.lazy(() => import("./components/pages/uiadvance/Cropper"));
const Draggable = React.lazy(() => import("./components/pages/uiadvance/Draggable"));
const Modals = React.lazy(() => import("./components/pages/uiadvance/Modals"));
const Rangeslider = React.lazy(() => import("./components/pages/uiadvance/Rangeslider"));
const Sliders = React.lazy(() => import("./components/pages/uiadvance/Sliders"));
const Tour = React.lazy(() => import("./components/pages/uiadvance/Tour"));
const Ratings = React.lazy(() => import("./components/pages/uiadvance/Ratings"));
// uibasic
const Accordions = React.lazy(() => import("./components/pages/uibasic/Accordions"));
const Alerts = React.lazy(() => import("./components/pages/uibasic/Alerts"));
const Badges = React.lazy(() => import("./components/pages/uibasic/Badges"));
const Breadcrumbs = React.lazy(() => import("./components/pages/uibasic/Breadcrumbs"));
const Buttons = React.lazy(() => import("./components/pages/uibasic/Buttons"));
const Cards = React.lazy(() => import("./components/pages/uibasic/Cards"));
const Pagination = React.lazy(() => import("./components/pages/uibasic/Pagination"));
const Preloaders = React.lazy(() => import("./components/pages/uibasic/Preloader"));
const Progressbars = React.lazy(() => import("./components/pages/uibasic/Progressbars"));
const Tabs = React.lazy(() => import("./components/pages/uibasic/Tabs"));
const Typography = React.lazy(() => import("./components/pages/uibasic/Typography"));

function App() {
  return (
    <div>
      <div> <Toaster/> </div>
      <Router basename={'/'}>
      <Suspense fallback={<div></div>}>
        <Preloader />
        <Switch>
          <Route exact path="/" component={Defaultlogin} />         
          <Route path="/animation" component={Animation} />
          <Route path="/widgets" component={Widgets} />
          
          {/* appointment */}
          <Route path="/appointment/add" component={Addappointment} />
          <Route path="/appointment/list" component={Appointmentlist} />
          
          {/* appointment page for receptionist */}
          <Route path="/receptionist/appointment/add" component={AddappointmentReceptionist} />
          <Route path="/receptionist/appointment/list" component={AppointmentlistReceptionist} />


          {/* appointment list for doctor */}
          <Route path ="/doctor/my-appointments" component={AppointmentlistDoctor}/>

          {/* appointment list for patient */}
          <Route path ="/patient/my-appointments" component={AppointmentlistPatient}/>
          
          {/* appointment Vital Signs*/}
          <Route path ="/nurse/appointment/vital/signs/add" component={AddAppointmentVitalSigns}/>
          <Route path ="/admin/appointment/vital/signs/edit" component={EditAppointmentVitalSigns}/>

          <Route path ="/doctor/appointment/vital/signs/edit" component={EditAppointmentVitalSignsDoctor}/>

          {/* apps */}
          <Route path="/apps/chat" component={Chat} />
          <Route path="/apps/email" component={Email} />
          <Route path="/apps/to-do-list" component={Todolist} />

          {/* Room manager */}
          <Route path="/room/category/add" component={AddRoomCategory} />
          <Route path="/room/category/list" component={RoomCategoryList} />
          <Route path="/room/add" component={AddRoom}/>
          <Route path="/room/list" component={RoomList}/>

          {/* bed manager */}
          <Route path="/bed/category/add" component={Addbedcategory} />
          <Route path="/bed/category/list" component={Bedcategorylist} />
          <Route path="/bed/add" component={Addbed} />          
          <Route path="/bed/list" component={Bedlist} />

          <Route path="/receptionist/bed/list" component={BedlistReceptionist}/>

          {/* charts */}
          <Route path="/charts/chartjs" component={Chartjs} />
          <Route path="/charts/google-charts" component={Googlecharts} />

          {/* dashboard */}
          <Route path="/dashboard/client-management" component={Clientmanagement} />
          <Route path="/dashboard/project-management" component={Projectmanagement} />
          <Route path="/dashboard/social-media" component={Socialmedia} />
          <Route path="/dashboard/web-analytics" component={Webanalytics} />

          {/* department */}
          <Route path="/department/add-department" component={Adddepartment} />
          <Route path="/department/department-list" component={Departmentlist} />

          {/* Department list page for receptionist */}
          <Route path="/receptionist/department/department-list" component={DepartmentlistReceptionist}/>

          {/* patient */}
          <Route path="/patient/homepage" component={PatientHomepage}/>

          {/* doctor */}
          <Route path="/doctor/add" component={Adddoctor} />
          <Route path="/doctor/list" component={Doctorlist} />
          <Route path="/doctor/homepage" component={DoctorHomepage} />

          {/* doctorlist page for receptionist */}
          <Route path="/receptionist/doctor/list" component={DoctorlistReceptionist} />

                
          {/* doctor schedule */}
          <Route path="/doctor-schedule/add-schedule" component={Addschedule} />
          <Route path="/doctor-schedule/schedule-list" component={Schedulelist} />
          
          {/* doctor schedule page for receptionist */}
          <Route path="/receptionist/doctor-schedule/schedule-list" component={SchedulelistReceptionist} />

          {/* Consultation */}
          <Route path="/admin/consultation/add" component = {Addconsultation} />
          <Route path="/admin/consultation/list" component ={Consultationlist}/>
          <Route path="/admin/consultation/details" component ={Consultationdetails }/>
          <Route path="/admin/consultation/edit" component ={Editconsultation}/>
          <Route path="/admin/consultation/delete" component={Deleteconsultation}/>

          <Route path={"/doctor/consultation/add"} component = {AddconsultationDoctor} />
          <Route path={"/doctor/consultation/today"} component= {ConsultationlistDoctor}/>
          <Route path={"/doctor/patients"} component={PatientlistDoctor}/>
          <Route path={"/doctor/consultation/details"} component= {ConsultationdetailsDoctor}/>
          <Route path={"/doctor/consultation/edit"} component = {EditconsultationDoctor} />
          <Route path={"/doctor/consultation/delete"} component = {DeleteconsultationDoctor} />

          <Route path= "/doctor/consultation/list/patients" component={PatientconsultationList}/>

          <Route path="/patient/my-consultations" component={ConsultationlistPatient}/>
          <Route path= "/patient/consultation-details" component={ConsultationdetailsPatient}/>

          <Route path="/patient/pcc/form" component={PatientCaseReport} />
          <Route path="/receptionist/patient/pcc/form" component={PatientCaseReportReceptionist} />

          {/* Prescription*/}

          {/* for Admin */}
          <Route path="/admin/prescription/add" component = {Addprescription} />
          <Route path="/prescription/list" component ={Prescriptionlist} />
          <Route path="/prescription/details" component= {Prescriptiondetails} />
          <Route path="/admin/prescription/edit" component={Editprescription} />
          <Route path="/admin/prescription/delete" component={Deleteprescription} />

          {/* for Doctor */}
          <Route path="/doctor/prescription/add" component= {AddprescriptionDoctor} />
          <Route path= "/doctor/prescription/details" component= {PrescriptiondetailsDoctor} />
          <Route path= "/doctor/prescription/edit" component= {EditprescriptionDoctor} />
          <Route path= "/doctor/prescription/delete" component= {DeleteprescriptionDoctor} />
          
          {/* for Pharmacist */}
          <Route path= "/pharmacist/admitted/patient/prescription/list" component= {AdmittedPatientPrescriptionListPharmacist} />
          <Route path= "/pharmacist/prescription/details" component= {PrescriptiondetailsPharmacist} />
          <Route path= "/pharmacist/prescription/list" component ={PrescriptionlistPharmacist} />
          
          {/* for Receptionist */}
          <Route path= "/receptionist/prescription/list" component={PrescriptionListReceptionist}/>
          <Route path= "/receptionist/prescription/details" component= {PrescriptionDetailsReceptionist} />
          <Route path="/receptionist/prescription/test/details" component={PrescriptionTestDetailsReceptionist}/>

          {/* for Patient */}
          <Route path= "/patient/prescription-list" component={PrescriptionlistPatient}/>
          <Route path= "/patient/prescription-details" component={PrescriptiondetailsPatient}/>

          {/* for Pathologist */}
          <Route path= "/pathologist/admitted/patient/prescription/list" component= {AdmittedPatientPrescriptionListPathologist} />
          <Route path= "/pathologist/prescription/list" component={PrescriptionlistPathologist} />
          <Route path="/pathologist/prescription/details" component={PrescriptiontestdetailsPathologist}/>

          {/* Prescription test*/}
          
          {/* for Admin */}
          <Route path="/admin/prescription/test/add" component = {Addtest} />
          <Route path="/admin/prescription/test/details" component={Prescriptiontestdetails} />
          <Route path="/admin/prescription/test/edit" component = {Edittest}/>
          <Route path="/admin/prescription/test/delete" component = {Deletetest}/>

          {/* for Doctor */}
          <Route path="/doctor/prescription/test/add" component = {AddtestDoctor} />
          <Route path="/doctor/prescription/test/details" component={PrescriptiontestdetailsDoctor} />
          <Route path="/doctor/prescription/test/edit" component = {EdittestDoctor}/>
          <Route path="/doctor/prescription/test/delete" component = {DeletetestDoctor}/>
          
          {/* for Patient */}
          <Route path="/patient/consultation/prescription-test-details" component={PrescriptiontestdetailsPatient} />
          
          {/* for Pathologist */}
          <Route path="/pathologist/prescription/test/edit" component = {EditTestPathologist}/>
          <Route path="/pathologist/prescription/test/list" component = {PrescriptionTestListPathologist}/>
          
          {/* Follow up*/}
          <Route path="/admin/consultation/followup/list" component ={Followuplist}/>
          <Route path="/admin/consultation/followup/add" component ={Addfollowup}/>

          <Route path="/doctor/consultation/followup/add" component={AddfollowupDoctor}/>
          <Route path="/doctor/consultation/followup/list" component={FollowuplistDoctor}/>

          {/* Available Drugs, Accessories and Tests of Pharmacy, Pathology and Admin*/}

          {/* For Admin */}
          <Route path = "/pharmacy/drugs/add" component={AddDrugs} />
          <Route path = "/pharmacy/drugs/list" component={Availabledrugs} />

          <Route path = "/pharmacy/accessories/add" component = {AddAccessories} />
          <Route path = "/pharmacy/accessories/list" component={Availableaccessories} />

          <Route path = "/pathology/procedures/add" component={AddProcedures} />
          <Route path = "/pathology/procedures/list" component={Availableprocedures}/>

          <Route path = "/pathology/tests/add" component={AddTests} />
          <Route path = "/pathology/tests/list" component={Availabletests} />

          <Route path = "/pathology/services/add" component={AddServices} />
          <Route path = "/pathology/services/list" component={AvailableServices} />
          
          {/* For Pharmacist*/}
          <Route path = "/pharmacist/pharmacy/drugs/add" component={AddDrugsPharmacist} />
          <Route path = "/pharmacist/pharmacy/accessories/add" component = {AddAccessoriesPharmacist} />
          <Route path = "/pharmacist/pharmacy/drugs/list" component={AvailabledrugsPharmacist} />
          <Route path = "/pharmacist/pharmacy/accessories/list" component={AvailableaccessoriesPharmacist} />

          <Route path = "/pathologist/tests/list" component={AvailabletestsPathologist} />

          {/* form */}
          <Route path="/form/form-layout" component={Formlayouts} />
          <Route path="/form/form-validation" component={Formvalidation} />
          <Route path="/form/form-wizard" component={Formwizard} />
          <Route path="/form/form-elements" component={Fromelements} />
          {/* human resource */}
          <Route path="/staff/add" component={Addemployee} />
          <Route path="/human-resource/add-nurse" component={Addnurse} />
          <Route path="/human-resource/add-pharmacist" component={Addpharmacist} />
          <Route path="/human-resource/add-representative" component={Addrepresentative} />
          <Route path="/staff/list" component={Employeelist} />
          <Route path="/human-resource/nurse-list" component={Nurselist} />
          <Route path="/human-resource/pharmacist-list" component={Pharmacistlist} />
          <Route path="/human-resource/representative-list" component={Representativelist} /> 

          <Route path={"/admin/homepage"} component={Adminhomepage}/>
          <Route path="/receptionist/homepage" component={Receptionisthomepage}/>
          <Route path="/pharmacist/homepage" component={Pharmacisthomepage}/>
          <Route path="/pathologist/homepage" component={Pathologisthomepage} />
          <Route path="/nurse/homepage" component={NurseHomepage} />

          {/* newly added */}
          {/* <Route
          path='/human-resource/admin-homepage'
          render={()=>(
            <PrivateRoute redirectTo ='/' > 
            <Adminhomepage />
            </PrivateRoute>
          )} />

          <Route
          path='/human-resource/receptionist-homepage'
          render={()=>(
            <PrivateRoute redirectTo ='/' > 
            <Receptionisthomepage />
            </PrivateRoute>
          )} />       

          <Route
          path='/human-resource/pharmacist-homepage'
          render={()=>(
            <PrivateRoute redirectTo ='/' > 
            <Pharmacisthomepage />
            </PrivateRoute>
          )} /> */}
          


          {/* icons */}
          <Route path="/icons/flaticons" component={Flaticons} />
          <Route path="/icons/fontawesome" component={Fontawesome} />
          <Route path="/icons/materialize" component={Materialize} />
          {/* maps */}
          <Route path="/maps/google-maps" component={Googlemaps} />
          <Route path="/maps/vector-maps" component={Vectormaps} />
          {/* notice */}
          <Route path="/notice/add-notice" component={Addnotice} />
          <Route path="/notice/notice-list" component={Noticelist} />
          <Route path="/receptionist/notice/notice-list" component={NoticelistReceptionist} />

          {/* patient */}
          
          {/* for Admin */}
          <Route path ="/admin/patient/add" component={Addpatient} />
          <Route path ="/admin/patient/list" component={Patientlist}/>
          <Route path = "/admin/patient/admit/request/list" component={PatientAdmitRequestList} />
          
          <Route path = "/admin/patient/admit" component={Admitpatient} />
          <Route path = "/admin/patient/admitted/list" component={Admittedpatientlist}/>
          <Route path = "/admin/admitted/patient/details" component={Admittedpatientdetails} />
          <Route path = "/admin/admitted/patient/edit" component={Editadmittedpatientdetails}/>
          <Route path = "/admin/admitted/patient/delete" component={Deleteadmittedpatientdetails} />
          <Route path = "/admin/admitted/patient/release" component={Releasepatient} />

          <Route path = "/admin/admitted/patient/followup/add" component={AddAdmittedPatientFollowup} />

          <Route path = "/admin/admitted/patient/procedure/add" component={AddPatientProcedure} />
          <Route path = "/admin/admitted/patient/procedure/edit" component={EditPatientProcedure}/>
          <Route path = "/admin/admitted/patient/procedure/delete" component={DeletePatientProcedure}/>

          {/* for Doctor */}
          <Route path = "/doctor/patient/admitted/list" component={AdmittedpatientlistDoctor}/>
          <Route path = "/doctor/admitted/patient/details" component={AdmittedpatientdetailsDoctor}/>
          <Route path = "/doctor/admitted/patient/followup/add" component={AddAdmittedPatientFollowupDoctor} />
          
          {/* for Receptionist */}
          <Route path = "/receptionist/patient/add" component={AddpatientReceptionist} />
          {/* <Route path = "/receptionist/patient/list" component={PatientlistReceptionist} /> */}
          <Route path = "/receptionist/patient/admit/request/list" component={PatientAdmitRequestListReceptionist} />

          <Route path = "/receptionist/patient/admit" component={AdmitpatientReceptionist} />
          <Route path = "/receptionist/patient/admitted/list" component={AdmittedpatientlistReceptionist} />
          <Route path = "/receptionist/admitted/patient/details" component={AdmittedpatientdetailsReceptionist} />
          <Route path = "/receptionist/admitted/patient/edit"  component={EditadmittedpatientdetailsReceptionist}/>
          <Route path = "/receptionist/admitted/patient/delete" component={DeleteadmittedpatientdetailsReceptionist} />
          <Route path = "/receptionist/admitted/patient/release" component={ReleasepatientReceptionist} />

          <Route path = "/receptionist/patient/admit/request/list" component={PatientAdmitRequestListReceptionist} />
          <Route path = "/receptionist/admitted/patient/procedure/add" component={AddPatientProcedureReceptionist} />
          <Route path = "/receptionist/admitted/patient/procedure/edit" component={EditPatientProcedureReceptionist}/>
          <Route path = "/receptionist/admitted/patient/procedure/delete" component={DeletePatientProcedureReceptionist}/>
          
          {/* for Pharmacist & Pathologist */}
          <Route path = "/pharmacist/patient/admitted/list" component={AdmittedPatientListPharmacist} />
          <Route path = "/pharmacist/patient/list" component={PatientlistPharmacist} />
          <Route path = "/pathologist/patient/admitted/list" component={AdmittedPatientListPathologist} />
          <Route path = "/pathologist/patient/list" component={PatientlistPathologist}/>

          {/* payment */}
          <Route path="/payment/add-payment" component={Addpayment} />
          <Route path="/payment/payment-invoice" component={Paymentinvoice} />

          {/* For Admin */}
          <Route path="/payment/pending/list" component={Paymentlist} />
          <Route path="/payment/service/invoice" component={PatientServiceInvoice} />
          <Route path={"/payment/details"} component ={PaymentDetails} />
          <Route path={"/payment/service"} component ={AdmittedPatientServiceBill} />
          
          {/* For Receptionist */}
          <Route path="/receptionist/payment/pending/list" component={PaymentlistReceptionist} />
          <Route path="/receptionist/payment/service/invoice" component={PatientServiceInvoiceReceptionist} />
          <Route path="/receptionist/payment/pathology/invoice" component={PathologyinvoiceReceptionist}/>
          <Route path="/receptionist/payment/details" component ={PaymentDetailsReceptionist} />
          <Route path={"/receptionist/payment/service"} component ={AdmittedPatientServiceBillReceptionist} />

          {/* For Pharmacist & Pathologist */}
          <Route path="/pharmacist/payment/invoice" component={PaymentinvoicePharmacist}/>
          <Route path="/pathologist/payment/invoice" component={PaymentinvoicePathologist}/>
          
          
          {/* popups */}
          <Route path="/popups/sweet-alerts" component={Sweetalerts} />
          <Route path="/popups/toast" component={Toast} />
          {/* prebuilt pages */}
          <Route path="/prebuilt-pages/coming-soon" component={Comingsoon} />
          {/* <Route path="/prebuilt-pages/default-login" component={Defaultlogin} /> */}
          <Route path="/prebuilt-pages/default-register" component={Defaultregister} />
          <Route path="/prebuilt-pages/error" component={Error} />
          <Route path="/prebuilt-pages/faq" component={Faq} />
          <Route path="/prebuilt-pages/invoice" component={Invoice} />
          <Route path="/prebuilt-pages/lock-screen" component={Lockscreen} />
          <Route path="/prebuilt-pages/modal-login" component={Modallogin} />
          <Route path="/prebuilt-pages/modal-register" component={Modalregister} />
          <Route path="/prebuilt-pages/portfolio" component={Portfolio} />
          <Route path="/prebuilt-pages/user-profile" component={Userprofile} />
          
          {/* Receptionist User Profile */}
          <Route path="/receptionist-profile" component={UserprofileReceptionist} />

          {/* reports */}
          <Route path="/report/doctor-report" component={Doctorreport} />
          <Route path="/report/patient-report" component={Patientreport} />
          <Route path="/report/total-report" component={Totalreport} />

          <Route path = "/receptionist/report/patient-report" component={PatientreportReceptionist} />
          <Route path = "/receptionist/report/doctor-report" component={DoctorreportReceptionist}/>
          <Route path = "/receptionist/report/total-report" component={TotalreportReceptionist}/>
          {/* tables */}
          <Route path="/tables/basic-tables" component={Basictables} />
          <Route path="/tables/data-tables" component={Datatables} />
          {/* uiadvance */}
          <Route path="/ui-advanced/cropper" component={Cropper} />
          <Route path="/ui-advanced/draggables" component={Draggable} />
          <Route path="/ui-advanced/modals" component={Modals} />
          <Route path="/ui-advanced/range-slider" component={Rangeslider} />
          <Route path="/ui-advanced/sliders" component={Sliders} />
          <Route path="/ui-advanced/tour" component={Tour} />
          <Route path="/ui-advanced/rating" component={Ratings} />
          {/* uibasic */}
          <Route path="/ui-basic/accordions" component={Accordions} />
          <Route path="/ui-basic/alerts" component={Alerts} />
          <Route path="/ui-basic/badges" component={Badges} />
          <Route path="/ui-basic/breadcrumbs" component={Breadcrumbs} />
          <Route path="/ui-basic/buttons" component={Buttons} />
          <Route path="/ui-basic/cards" component={Cards} />
          <Route path="/ui-basic/pagination" component={Pagination} />
          <Route path="/ui-basic/preloader" component={Preloaders} />
          <Route path="/ui-basic/progress-bars" component={Progressbars} />
          <Route path="/ui-basic/tabs" component={Tabs} />
          <Route path="/ui-basic/typography" component={Typography} />
        </Switch>
      </Suspense>
      </Router>
    </div>
    
  );
}

export default App;
