import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";

import loggedReducer from "./isLoggedIn";
import userDetailsReducer from "./userDetails";

const appReducer = combineReducers({
    isLoggedIn: loggedReducer,
    userDetails: userDetailsReducer
});

const indexReducer = (state, action) =>{
    if(action.type === "SIGN_OUT"){
        storage.removeItem('persist:root')
        return appReducer(undefined, action);
    }
    return appReducer(state,action)
}

export default indexReducer;